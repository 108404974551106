<div class="wheel-legend-container">
    <app-wheel-chart-legend
        *appHideForUnavailableFeature="features.LowHighIndicator; 
        else lowHighIndicatorPlaceholder"
    ></app-wheel-chart-legend>
  
    <ng-template #lowHighIndicatorPlaceholder>
      <div class="low-high-indicator-placeholder">
        <button
          mat-button
          class="mat-button-link link-button locked-for-current-edition"
          (click)="$event.preventDefault(); redirectToLowHighIndicatorDemoPage()"
        >
          Lowest / Highest Close
        </button>
      </div>
    </ng-template>
</div>
<div class="app-tv-chart-wheel-container" id="tv_chart_container_wheel"></div>
