<app-maintenance></app-maintenance>

<app-strategy-skeleton [isVisible]="isWheelScannerLoadingFirstTime"></app-strategy-skeleton>

<!--------------------------------------------------------------
This is separate fragment for only web UI
--------------------------------------------------------------->

<div
  *ngIf="!isMobile"
  class="main-menu"
>
  <app-search-bar
    [isPowerX]="false"
    #appSearchBar
  ></app-search-bar>
  <app-trading-log-symbol-summary-panel
    [state]="observableServiceV2.tradingLogSymbolSummaryPanelWheelState.value"
    [symbolName]="appSearchBar.selectedSymbol?.symbol"
    (selected)="summaryPanelValueSelected($event)"
    (statechanged)="summaryPanelStateUpdates($event)"
    *appHideForUnavailableFeature="features.TradingLog"
  ></app-trading-log-symbol-summary-panel>
  @if (!showMaintenance) {
    <div class="right-side">
      <div
        (click)="wheelPositionSizing()"
        class="account-size"
      >
        <div class="size">
          <span>Buying Power</span>
          <p>${{ userSetting.wBuyingPower | number: '1.0-0' }}</p>
        </div>
        <div class="risk">
          <span>Pos.</span>
          <p>{{ userSetting.wMaxPosition }}</p>
        </div>
      </div>
    </div>
    <div class="view-mode-controls hide-on-max-991">
      <button
        [ngClass]="{ active: (wheelViewMode$ | async) === wheelViewModes.Tabs }"
        (click)="changeViewMode(wheelViewModes.Tabs)"
        mat-icon-button
        class="view-mode-button"
      >
        <mat-icon
          svgIcon="tabs-mode-icon"
          class="middle-icon icon tabs-mode-icon"
        ></mat-icon>
      </button>

      <button
        [ngClass]="{ active: (wheelViewMode$ | async) === wheelViewModes.Split }"
        (click)="changeViewMode(wheelViewModes.Split)"
        mat-icon-button
        class="view-mode-button"
      >
        <mat-icon
          svgIcon="split-mode-icon"
          class="middle-icon icon split-mode-icon"
        ></mat-icon>
      </button>
    </div>
  }

  <app-wheel-timer></app-wheel-timer>
</div>

<div
  [ngClass]="{ 'with-banner': isBanner, hidden: isWheelScannerLoadingFirstTime && selectedTab === tabsEnum.Chart }"
  *ngIf="!isMobile && !showMaintenance"
  class="wheel-section"
  id="wheel-section"
>
  <as-split
    (dragStart)="onDragStart()"
    (dragEnd)="saveWheelFiltersWidth($event); onDragEnd()"
    direction="horizontal"
    class="all-section"
    gutterSize="6"
    unit="percent"
  >
    <as-split-area
      [size]="showOrderPanel ? leftPartWidthWithPinnedOrderPanelInPercents : leftPartWidthInPercents"
      class="left-section"
    >
      <div
        *ngIf="(viewMode$ | async) === viewModes.Split"
        class="chart-wheel"
      >
        <as-split
          (dragStart)="onDragStart()"
          (dragEnd)="saveWheelChartHeight($event); onDragEnd()"
          direction="vertical"
          gutterSize="6"
          unit="percent"
        >
          <as-split-area
            [minSize]="wheelChartSectionMinHeightInPercents"
            [size]="wheelChartSectionHeightInPercents"
          >
            <div class="chart-back-layout">
              <div class="chart-inner-layout">
                <app-wheel-chart class="h-less-991"></app-wheel-chart>
                <div
                  *ngIf="isShowChartOverlay"
                  class="chart-overlay"
                ></div>
              </div>
            </div>
          </as-split-area>

          <as-split-area
            [minSize]="wheelCalcSectionMinHeightInPercents"
            [size]="wheelCalcSectionHeightInPercents"
          >
            <app-wheel-calculator></app-wheel-calculator>
          </as-split-area>
        </as-split>
      </div>

      <div
        *ngIf="(viewMode$ | async) === viewModes.Tabs"
        class="chart-wheel"
      >
        <div class="left-side-button">
          <mat-button-toggle-group
            [value]="selectedTab"
            (change)="onCalculatorToggle($event.value)"
            hideSingleSelectionIndicator="true"
            name="fontStyle"
            #group="matButtonToggleGroup"
          >
            <mat-button-toggle [value]="tabsEnum.Chart">Chart</mat-button-toggle>
            <mat-button-toggle [value]="tabsEnum.Calculator">Calculator</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="right-side-content">
          <ng-template [ngIf]="selectedTab !== tabsEnum.Calculator">
            <div class="chart-back-layout">
              <div class="chart-inner-layout">
                <app-wheel-chart class="h-less-991"></app-wheel-chart>
              </div>
            </div>
            <ng-content select="wheelChart"></ng-content>
          </ng-template>
          <ng-template [ngIf]="selectedTab === tabsEnum.Calculator">
            <app-wheel-calculator></app-wheel-calculator>
          </ng-template>
        </div>
      </div>
    </as-split-area>

    <as-split-area
      [size]="showOrderPanel ? rightPartWidthWithPinnedOrderPanelInPercents : rightPartWidthInPercents"
      [minSize]="showOrderPanel ? rightPartWidthWithPinnedOrderPanelInPercents : rightPartMinWidthInPercents"
      [maxSize]="showOrderPanel ? rightPartMaxWidthWithPinnedOrderPanelInPercents : rightPartMaxWidthInPercents"
      [ngClass]="{ 'pinned-order': showOrderPanel }"
      class="right-section pinned-order"
    >
      <app-trading-panel-order-panel *ngIf="showOrderPanel"></app-trading-panel-order-panel>
      <as-split
        (dragStart)="onDragStart()"
        (dragEnd)="saveWheelFilterHeight($event); onDragEnd()"
        direction="vertical"
        gutterSize="6"
      >
        <as-split-area
          [size]="wheelFilterHeight"
          minSize="40"
          class="right-one"
        >
          <app-wheel-scanner (changeCurrentSymbol)="onSetCurrentSymbol($event)"></app-wheel-scanner>
        </as-split-area>

        <as-split-area
          [size]="wheelDataWindowHeight"
          [minSize]="dataWindowMinHeightInPercents"
          class="right-two"
          gutterSize="11"
        >
          <app-wheel-datawindow></app-wheel-datawindow>
        </as-split-area>
      </as-split>
    </as-split-area>
  </as-split>
</div>

<!--------------------------------------------------------------
This is separate fragment for only mobile UI
--------------------------------------------------------------->
<div class="wheel-container scroll-style">
  <div class="main-menu">
    <h2 class="mobile-header">The Wheel</h2>
    <app-search-bar
      [isPowerX]="false"
      #appSearchBar
    ></app-search-bar>
    <app-trading-log-symbol-summary-panel
      [state]="observableServiceV2.tradingLogSymbolSummaryPanelWheelState.value"
      [symbolName]="appSearchBar.selectedSymbol?.symbol"
      (selected)="summaryPanelValueSelected($event)"
      (statechanged)="summaryPanelStateUpdates($event)"
      *appHideForUnavailableFeature="features.TradingLog"
    ></app-trading-log-symbol-summary-panel>
    @if (!showMaintenance) {
      <div class="right-side">
        <div
          (click)="wheelPositionSizing()"
          class="account-size"
        >
          <div class="size">
            <span>Buying Power</span>
            <p>${{ userSetting.wBuyingPower | number: '1.0-0' }}</p>
          </div>
          <div class="risk">
            <span>Pos.</span>
            <p>{{ userSetting.wMaxPosition }}</p>
          </div>
        </div>
      </div>
      <div class="view-mode-controls hide-on-max-991">
        <button
          [ngClass]="{ active: (wheelViewMode$ | async) === wheelViewModes.Tabs }"
          (click)="changeViewMode(wheelViewModes.Tabs)"
          mat-icon-button
          class="view-mode-button"
        >
          <mat-icon
            svgIcon="tabs-mode-icon"
            class="middle-icon icon tabs-mode-icon"
          ></mat-icon>
        </button>

        <button
          [ngClass]="{ active: (wheelViewMode$ | async) === wheelViewModes.Split }"
          (click)="changeViewMode(wheelViewModes.Split)"
          mat-icon-button
          class="view-mode-button"
        >
          <mat-icon
            svgIcon="split-mode-icon"
            class="middle-icon icon split-mode-icon"
          ></mat-icon>
        </button>
      </div>
    }

    <app-wheel-timer></app-wheel-timer>
  </div>

  <div
    [ngClass]="{ hidden: isWheelScannerLoadingFirstTime && selectedTab === tabsEnum.Chart }"
    *ngIf="isMobile && !showMaintenance"
    class="wheel-section"
  >
    <div class="chart-wheel">
      <div class="left-side-button">
        <mat-button-toggle-group
          [(value)]="selectedTab"
          (change)="onCalculatorToggle($event.value)"
          hideSingleSelectionIndicator="true"
          name="fontStyle"
          #group="matButtonToggleGroup"
        >
          <mat-button-toggle [value]="tabsEnum.Chart">Symbols</mat-button-toggle>
          <mat-button-toggle [value]="tabsEnum.Calculator">Calculator</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="right-side-content scroll-style">
        <app-wheel-calculator *ngIf="selectedTab === tabsEnum.Calculator"></app-wheel-calculator>

        <div
          *ngIf="selectedTab !== tabsEnum.Calculator"
          class="wheel-scanner-part"
        >
          <div class="consAndAgg">
            <app-wheel-scanner (changeCurrentSymbol)="onSetCurrentSymbol($event)"></app-wheel-scanner>
          </div>
          <div class="premiums-chart-tabbing">
            <div class="left-side-button">
              <mat-button-toggle-group
                [value]="premiumsValue"
                (change)="onToggleSubTabbing($event.value)"
                hideSingleSelectionIndicator="true"
                name="fontStyle"
                #group="matButtonToggleGroup"
              >
                <mat-button-toggle [value]="true">Premiums</mat-button-toggle>
                <mat-button-toggle [value]="false">Chart</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div
              [ngClass]="{ hidden: !premiumsValue }"
              class="premiums-section"
            >
              <app-wheel-datawindow></app-wheel-datawindow>
            </div>
            <div
              [ngClass]="{ hidden: premiumsValue }"
              class="chart-back-layout"
            >
              <div class="chart-inner-layout">
                <app-wheel-chart></app-wheel-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="isWheelScannerLoadingFirstTime && !showMaintenance && selectedTab === tabsEnum.Chart"
  class="spinner-container"
>
  <mat-spinner
    class="blue-spinner"
    diameter="50"
  ></mat-spinner>
</div>
