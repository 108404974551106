import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'momentPipe',
})
export class MomentPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    let [format] = args;
    return moment.unix(value).format(format);
  }
}
