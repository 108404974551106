import { Directive, ElementRef, HostListener } from '@angular/core';
import { position, offset } from 'caret-pos';
import { NgModel } from "@angular/forms";
import numeral from 'numeral';
import { removeComma } from '@u/utils';

@Directive({
  selector: 'input[inputformat]'
})
export class InputFormatterDirective {
  constructor(private _el: ElementRef, private model: NgModel) { }

  @HostListener('input', ['$event']) onInput(event) {
    let _regExNumber = new RegExp(/^[0-9]*$/g);
    let _regExNumberAndDecimal = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);
    let originalVal = this._el.nativeElement.value.replace(/[^0-9.]/g, '');
    originalVal = this.processDots(originalVal)
    let minVal = this._el.nativeElement.min;
    let maxVal = this._el.nativeElement.max;
    let rawMinval = parseFloat(this.getRawValue(minVal))
    let rawMaxval = parseFloat(this.getRawValue(maxVal))
    let elementVal = (this.getRawValue(originalVal)) ? parseFloat(this.getRawValue(originalVal)) : 0;
    let elementDecimalVal = this.getDecimalPart(originalVal)
    let elementNumericVal = (this.getNumericPart(this.getRawValue(originalVal))) ? parseInt(this.getNumericPart(this.getRawValue(originalVal))) : 0;
    let maxValLength = this.getNumericPart(maxVal).length
    let step = (this._el.nativeElement.step) ? this._el.nativeElement.step : '1'
    step = (this.getDecimalPart(step).length) ? this.getDecimalPart(step).length - 1 : '0'
    const input = document.querySelector('#' + this._el.nativeElement.id);
    step = parseInt(step)
    let selectionEnd = this._el.nativeElement.selectionEnd
    let valLength = originalVal.length


    if (rawMinval <= elementVal && rawMaxval >= elementVal && elementDecimalVal.length <= step) {
      if (originalVal == ".") {
        this._el.nativeElement.value = "0."
        const input = document.querySelector('#' + this._el.nativeElement.id);
        position(input, originalVal.length - 0);
        this.setModel("0.")
      }
      else {
        this._el.nativeElement.value = this.numberWithCommas(originalVal)
        this.setModel(this.numberWithCommas(originalVal))
      }
    } else {
      let finalVal = elementNumericVal.toString();
      if (rawMinval > elementVal) {
        finalVal = minVal
      }
      if (rawMaxval < elementVal) {
        finalVal = elementNumericVal.toString().substr(0, maxValLength)
      }
      if (elementDecimalVal.length > step) {
        if (step) {
          finalVal = finalVal + elementDecimalVal.substr(0, step + 1)
        }
        else {
          finalVal = finalVal + elementDecimalVal.substr(1, step + 1)
        }
      }
      this._el.nativeElement.value = this.numberWithCommas(finalVal)
      this.setModel(this.numberWithCommas(finalVal))
    }
    let valLengthAfter = this._el.nativeElement.value.length
    if (valLengthAfter == valLength) {
      this.setPos(input, selectionEnd)
    }
    else {
      let diffEnd = valLengthAfter - valLength
      this.setPos(input, selectionEnd + diffEnd)
    }
  }

  @HostListener('blur', ['$event'])
  onEvent(e) {
    let step = (e.target.step) ? e.target.step : '1'
    step = (this.getDecimalPart(step).length) ? this.getDecimalPart(step).length - 1 : '0'
    step = parseInt(step)
    if (e.target.value != "" && step == 0) {
      e.target.value = numeral(e.target.value).format('0,0');
      this.setModel(numeral(e.target.value).format('0,0'))
    }
    else if (e.target.value != "" && step == 1) {
      e.target.value = numeral(e.target.value).format('0,0.0');
      this.setModel(numeral(e.target.value).format('0,0.0'))
    }
    else if (e.target.value != "" && step == 2) {
      e.target.value = numeral(e.target.value).format('0,0.00');
      this.setModel(numeral(e.target.value).format('0,0.00'))
    }
  }

  @HostListener('change', ['$event'])
  onModelChange(e) {
    console.log(e)
    this.setModel(e.target.value)
  }

  setModel(value: string) {
    let newValue = removeComma(value);
    this.model.valueAccessor.writeValue(value);
    this.model.viewToModelUpdate(newValue);
  }

  setPos(input, pos) {
    position(input, pos);
  }

  processDots(input) {
    let index = input.indexOf('.');
    if (index > -1) {
      input = input.substr(0, index + 1) + input.slice(index).replace(/\./g, '');
    }
    return input;
  }


  numberWithCommas(x) {
    x = removeComma(x);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getRawValue(_value) {
    let returnVal;
    if (_value == null || _value.trim() === '')
      returnVal = '';
    else
      returnVal = this.removeLeadingZero(removeComma(this.getNumericPart(_value))) + this.getDecimalPart(_value);
    return returnVal;
  }

  removeLeadingZero(_value) {
    return Number(_value).toString();
  }

  getNumericPart(_value) {
    let returnVal = '';
    let splitValue = _value.split('.');
    returnVal = splitValue[0];
    return returnVal;
  }

  getDecimalPart(_value) {
    let returnVal = '';
    let splitValue = _value.split('.');
    if (splitValue.length > 1)
      returnVal = `.${splitValue[1]}`;
    return returnVal;
  }

}
